export default function YourOrders() {
  return(
    <div>

<p className="border border-danger m-3 p-3">I'm working on this page. Thanks</p>

<h1>Your order</h1>
<p>You have not ordered any product yet</p>


<h1>Messages</h1>
<p>You need to update your address</p>

<label>Update address</label>
<input className="form-control" placeholder="Enter shipping address"></input>
<button className="btn">Submit</button>
    </div>
  )
}